import axios from 'axios';

const api = axios.create({
    baseURL: '/wordapi', // 接口基础 URL
    timeout: 10000, // 超时时间，单位为毫秒
    headers: {
        'Content-Type': 'application/json' // 设置请求头
    }
});

// // 添加请求拦截器
// api.interceptors.request.use(config => {
//     // 在发送请求之前做些什么
//     console.log('请求拦截器', config);
//     return config;
// }, error => {
//     // 对请求错误做些什么
//     console.error('请求拦截器错误', error);
//     return Promise.reject(error);
// });

// // 添加响应拦截器
// api.interceptors.response.use(response => {
//     // 对响应数据做点什么
//     console.log('响应拦截器', response);
//     return response;
// }, error => {
//     // 对响应错误做点什么
//     console.error('响应拦截器错误', error);
//     return Promise.reject(error);
// });

export default {
    get(url, params) {
        return api.get(url, {
            params
        });
    },
    post(url, data) {
        return api.post(url, data);
    }
};