<template>
  <div>
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <xixiWord />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import xixiWord from "./components/xixiWord.vue";
export default {
  name: "App",
  components: {
    // HelloWorld,
    xixiWord,
  },
};
</script>

<style>
body {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0d1117;
}
</style>
