import Vue from 'vue'
import App from './App.vue'

import vant from 'vant';
import 'vant/lib/index.css';
Vue.use(vant);

import '@/assets/css/font.css';

Vue.config.productionTip = false

import axios from 'axios'
Vue.prototype.$http=axios;

new Vue({
  render: h => h(App),
}).$mount('#app')


