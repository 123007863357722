<template>
  <div>
    <van-button
      round
      type="info"
      icon="wap-home-o"
      color="#41474e"
      @click="reset()"
      v-if="showHome"
      style="
        width: 30px;
        height: 30px;
        margin: 10px;
        color: white;
        background: linear-gradient(to bottom, #41474e, #191b1d);
        position: absolute;
        right: 0;
      "
    />
    <van-tabbar>
      <van-tabbar-item :border="false">
        <van-search
          v-model="word"
          shape="round"
          placeholder="请输入汉字"
          left-icon=""
          input-align="center"
          background="transparent"
          @blur="onSearch"
        >
        </van-search
      ></van-tabbar-item>
    </van-tabbar>
    <div class="content">
      <center>
        <span
          v-if="pinyin"
          style="
            color: rgb(255, 255, 255);
            font-size: 30px;
            font-family: pinyin;
            width: 85%;
            display: inline-block;
            word-break: break-all;
            white-space: normal;
          "
          >{{ pinyin }}</span
        >
        <div ref="drawDiv" id="draw-area" @click="clickWord"></div>
      </center>
      <div v-if="showContent" class="contentBody">
        <van-row>
          <van-col span="14"
            ><span class="itemKey">[拼音]</span
            ><span
              class="itemValue"
              style="
                font-family: pinyin;
                display: inline-block;
                word-break: break-all;
                white-space: normal;
              "
              @click="getTongyinzi(pinyin)"
            >
              {{ pinyin }}</span
            ></van-col
          >
          <van-col span="10"
            ><span class="itemKey">[部首]</span
            ><span class="itemValue"> {{ bushou[0].radical }}</span></van-col
          >
        </van-row>
        <van-row>
          <van-col span="14"
            ><span class="itemKey">[结构]</span
            ><span class="itemValue"> {{ bushou[0].struct }}</span></van-col
          >
          <van-col span="10"
            ><span class="itemKey">[笔画]</span
            ><span class="itemValue" style="font-family: pinyin">
              {{ bihua }}</span
            ></van-col
          >
        </van-row>
        <van-row>
          <van-col span="24"
            ><span class="itemKey">[繁体]</span
            ><span class="itemValue">
              <span> {{ fanti }}</span></span
            ></van-col
          >
        </van-row>
        <van-row>
          <van-col span="24"
            ><span class="itemKey">[笔顺]</span
            ><span class="itemValue">
              <span v-for="(item, index) in bishun[0]" :key="index">
                {{ item }}</span
              >
            </span></van-col
          >
        </van-row>
        <van-row>
          <van-col span="24"
            ><span class="itemKey">[组词]</span
            ><span class="itemValue">
              <span v-for="(item, index) in zuci" :key="index">
                {{ item }}</span
              ></span
            ></van-col
          >
        </van-row>
        <van-row>
          <van-col span="24"
            ><span class="itemKey">[成语]</span
            ><span class="itemValue">
              <span v-for="(item, index) in chengyu" :key="index">
                {{ item }}</span
              ></span
            ></van-col
          >
        </van-row>
        <!-- <van-row>
          <van-col span="24"
            ><span class="itemKey">解释：</span
            ><span class="itemValue"> {{ jieshi }}</span></van-col
          >
        </van-row> -->
      </div>
    </div>
    <van-dialog
      v-model="showDialog"
      title="同音字"
      :close-on-click-overlay="true"
      :showConfirmButton="false"
      class="mydialog"
    >
      <div
        v-for="(item, index) in tongyinzi"
        :key="index"
        class="content"
        style="margin-left: 10px"
      >
        <van-row>
          <span class="itemKey" style="font-family: pinyin">
            {{ item.name }}</span
          >
        </van-row>
        <van-row>
          <span class="itemValue"> {{ item.value }}</span>
        </van-row>
      </div>
    </van-dialog>
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import { Toast } from "vant";
import { watch } from "vue";
// import cnchar from "cnchar-all";
import api from "../api";

export default {
  data() {
    return {
      word: "",
      // workWidth: window.screen.width - 2,
      showContent: false,
      pinyin: "",
      bihua: "",
      bishun: "",
      zuci: "",
      jieshi: "",
      chengyu: "",
      fanti: "",
      tongyinzi: [], //同音字
      showDialog: false,
      showHome: false,
    };
  },
  // watch: {
  //   word() {
  //     this.showHome = this.word.length > 0;
  //   },
  // },
  mounted() {
    this.word = "";
    // this.onSearch();
    cnchar.setResourceBase("https://cdn.ifelse.top/cnchar-data/");
  },
  methods: {
    validateChinese() {
      // Remove all whitespace from input
      this.word = this.word.replace(/\s/g, "");
      // Check if input only contains Chinese characters
      return /^[\u4e00-\u9fa5]+$/.test(this.word);
    },
    onSearch() {
      if (this.word != null && this.word != "" && this.validateChinese()) {
        if (this.word.indexOf("钱") > 0) {
          cnchar.radical.setRadical("钱", "钅"); //设置钱的部首
        }
        this.showHome = true;
        var drawArea = document.getElementById("draw-area");
        drawArea.innerHTML = "";
        var option = {
          type: cnchar.draw.TYPE.ANIMATION,
          animation: { loopAnimate: true },
          onComplete: () => {},
          clear: false,
          el: drawArea,
          style: {
            // radicalColor: "#44f",//部首
            backgroundColor: "#fff",
            length: 175,
          },
          line: {
            // lineColor:""//田字格
          },
        };
        cnchar.draw(this.word, option);
        var option1 = {
          type: cnchar.draw.TYPE.STROKE,
          animation: { loopAnimate: true },
          onComplete: () => {},
          clear: false,
          el: drawArea,
          style: {
            // radicalColor: "#44f",//部首
            backgroundColor: "#fff",
            length: 50,
          },
          line: {
            // lineColor:""//田字格
          },
        };
        cnchar.draw(this.word, option1);
        this.showContent = true;
        this.pinyin = cnchar.spell(this.word, "low", "poly", "tone");
        this.bihua = cnchar.stroke(this.word);
        this.bishun = cnchar.stroke(this.word, "order", "shape");
        this.bushou = cnchar.radical(this.word);
        this.jieshi = JSON.stringify(cnchar.explain(this.word));
        this.zuci = cnchar.words(this.word).slice(0, 15);
        this.chengyu = cnchar.idiom(this.word).slice(0, 15);
        this.fanti = cnchar.convert.simpleToTrad(this.word);
        // console.log('释义',cnchar.words(this.word),this.jieshi );

        api.get("https://api.ipify.org?format=json").then((response) => {
          var ip = response.data.ip;
          // 调用 save 接口
          api
            .get("/save?word=" + this.word + "&ip=" + ip)
            .then((response) => {
              console.log("success", response.data);
            })
            .catch((error) => {
              console.error("error", error);
            });
        });
      } else {
        this.showContent = false;
        this.showHome = false;
        Toast("请录入文字！");
      }
    },
    reset() {
      this.word = "";
      var drawArea = document.getElementById("draw-area");
      drawArea.innerHTML = "";
      this.showContent = false;
      this.pinyin = false;
      this.showHome = false;
    },
    clickWord() {
      //   cnchar.voice(this.word);
      cnchar.voice.speak(this.word);
    },
    getTongyinzi(pinyin) {
      this.tongyinzi = [];
      if (pinyin.indexOf("|")) {
        var str = pinyin.replace("(", "").replace(")", "");
        str.split("|").forEach((element) => {
          this.tongyinzi.push({
            name: element,
            value: cnchar.spellToWord(element, "simple").slice(0, 10),
          });
        });
      } else {
        this.tongyinzi.push({
          name: pinyin,
          value: cnchar.spellToWord(pinyin, "simple").slice(0, 10),
        });
      }
      this.showDialog = true;
      console.log(this.tongyinzi);
    },
  },
};
</script>

<style  scoped>
.header {
  width: 70%;
  height: 80px;
}
.content {
  font-family: "kaiti";
  padding: 10px;
}
.itemKey {
  font-weight: bold;
  font-size: 26px;
  line-height: 40px;
  color: #939aff;
}
.itemValue {
  font-size: 24px;
  line-height: 40px;
  color: #fff;
}
.van-tabbar {
  height: 80px;
  background: transparent;
}
.van-tabbar-item--active {
  background: transparent;
}
::v-deep .van-tabbar-item__text {
  width: 50%;
}
.van-search__content {
  background: linear-gradient(to bottom, #41474e, #191b1d);
  /* box-shadow: 0px 0px 15px 5px rgba(205,205,205,0.3); */
  height: 50px;
  line-height: 50px;
}
::v-deep .van-field__control {
  color: #fff;
  font-size: 22px;
  line-height: 40px;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: 0px;
}
.mydialog {
  background: #41474e;
}
::v-deep .van-dialog__header {
  color: #fff;
  font-family: "kaiti";
  font-size: 26px;
  font-weight: bold;
}
</style>